import React from "react";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { getBrandDetail, getMenu } from "../../../layouts/main/header/redux/selectors";
import { BASE_URL, EXTERNAL_GATEWAY_URL_PREFIX, ROOT_URL } from "../../../utils/constants/service";
import { getDataBySectionAndWidget, getLangPrefix, stripHTMLreg, getWebInventory, getIntlDate } from "../../../helpers/helpers";
import { BRAND, DEFAULT_CURRENCY, FACEBOOK_PIXEL } from "../../../utils/constants/variables";
import ReactPixel from 'react-facebook-pixel';



interface Props {
    menuList: any;
    pageKey?: string;
    productData?: any;
    navTabs?: any;
    location?: any;
    selectedMenuItem?: any;
    dataFAQ?: any;
    postData?: any;
    data?: any;
    brandDetails?: any;
}

interface State {
    selectedMenuItem: any;
    selectedItem: any;
    jsonLdData: any;
}

class PageMetaData extends React.Component<Props> {
    state: State = {
        selectedMenuItem: this.props.selectedMenuItem || {},
        selectedItem: this.props.productData && Object.keys(this.props.productData).length ? this.props.productData : this.props.selectedMenuItem ? this.props.selectedMenuItem : {},
        jsonLdData: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "url": window.location.protocol + "//" + window.location.host,
            "logo": window.location.protocol + "//" + window.location.host + "/img/logo.png",
        })
    };

    componentDidMount() {
        if (this.props.dataFAQ && this.props.dataFAQ.length) {
            const faqData = this.generateFAQDataForJsonLd(this.props.dataFAQ);
            Object.keys(faqData).length ? this.setState({ jsonLdData: JSON.stringify(faqData) }) : "";
        }

        if (this.props.pageKey && this.props.menuList && this.props.menuList.length) {
            this.setMenuItemByPageKey(this.props.pageKey, this.props.menuList)
        }

        if (window.caches) {
            caches.keys().then(cs => cs.forEach(c => caches.delete(c)));
        }

        if (FACEBOOK_PIXEL) {
            ReactPixel.init(`${FACEBOOK_PIXEL}`);
            ReactPixel.pageView(); 					// For tracking page view
        }
    }
    componentWillReceiveProps(nextProps) {

        if (nextProps.dataFAQ && nextProps.dataFAQ.length && this.props.dataFAQ && this.props.dataFAQ.length !== nextProps.dataFAQ.length) {
            const faqData = this.generateFAQDataForJsonLd(nextProps.dataFAQ);
            Object.keys(faqData).length ? this.setState({ jsonLdData: JSON.stringify(faqData) }) : "";
        }

        if (nextProps.menuList !== this.props.menuList && nextProps.menuList.length && this.props.pageKey) {
            this.setMenuItemByPageKey(this.props.pageKey, nextProps.menuList)
        }
        if (Array.isArray(nextProps.navTabs) && Array.isArray(this.props.navTabs) &&
            (nextProps.navTabs.length !== this.props.navTabs.length || nextProps.navTabs.length && this.props.navTabs.length && nextProps.navTabs[nextProps.navTabs.length - 1].url !== this.props.navTabs[this.props.navTabs.length - 1].url)) {


            if (nextProps.productData && Object.keys(nextProps.productData).length) {
                const { productData } = nextProps;
                const { storePromotion: { promotionEndDate = null, promotionStartDate = null } = {} } = this.props.brandDetails
                const sizes = productData.sizes || []
                const size = sizes && sizes.length && sizes.sort((a: Record<string, any>, b: Record<string, any>) => a.size - b.size)[0] || {};
                const isNotOutleted = productData.listPriceAfterDiscount && !productData.outlet

                const price = productData.listPriceAfterDiscount
                    ? !productData.outlet
                        ? size && size.startingPrice || productData.startingPrice
                        : size && size.listPriceAfterDiscount || productData.listPriceAfterDiscount
                    : size && size.startingPrice || productData.startingPrice

                const color = productData.color || ''
                const sizeFromSmallest = size ? size.size || '' : ''
                const InStoreOnly = this.props.productData && this.props.productData.sizes ? getWebInventory(this.props.productData.sizes) : true;
                const isPromoItem = this.checkIsPromo();
                const priceKey = isPromoItem ? "highPrice" : "price";
                const salePriceKey = isPromoItem ? "lowPrice" : "salePrice";
                const jsonLdData = {
                    "@context": "https://schema.org/",
                    "@type": "ItemPage",
                    "@graph": [
                        {
                            "@type": "Product",
                            ...(size.productReference && { "gtin13": size.productReference }),
                            ...(size.productId && { "id": size.productId.toString() }),
                            ...(color && { "color": color }),
                            ...(sizeFromSmallest && { "size": sizeFromSmallest }),
                            "name": productData.name,
                            "image": [window.location.protocol + "//" + window.location.host + "/externalgateway" + productData.photo],
                            "description": productData.description || '',
                            "mpn": productData.productId.toString(),
                            "brand": {
                                "@type": "Thing",
                                "name": productData.brandName
                            },
                            "offers": {
                                "@type": isPromoItem ? "AggregateOffer" : "Offer",
                                "url": window.location.protocol + "//" + window.location.host + window.location.pathname,
                                "priceCurrency": DEFAULT_CURRENCY,
                                [priceKey]: price,
                                "availability": this.props.productData.inSale === false ? "Discontinued" : InStoreOnly ? "InStoreOnly" : "InStock",
                                ...(isNotOutleted && {
                                    [salePriceKey]: size && size.listPriceAfterDiscount || productData.listPriceAfterDiscount,
                                    "validFrom": promotionStartDate ? getIntlDate(promotionStartDate) : '',
                                    "validThrough": promotionEndDate ? getIntlDate(promotionEndDate) : '',
                                }),
                            },
                        },
                        {
                            "@type": "BreadcrumbList",
                            "itemListElement": this.generateBreadCrumbsForJsonLd(nextProps.navTabs)
                        }
                    ]
                }
                this.setState({ jsonLdData: JSON.stringify(jsonLdData) })
            } else if (nextProps.postData && nextProps.postData.length) {
                const { postData } = this.props;

                let datePublished = new Date(getDataBySectionAndWidget(postData[0].section, 1, postData, "TEXT", "title").datePublished);
                let dateModified = new Date(getDataBySectionAndWidget(postData[0].section, 1, postData, "TEXT", "title").dateModified);

                const jsonLdData = {
                    "@context": "https://schema.org",
                    "@type": "NewsArticle",
                    "mainEntityOfPage": {
                        "@type": "WebPage",
                        "@id": window.location.protocol + "//" + window.location.host + window.location.pathname
                    },
                    "headline": getDataBySectionAndWidget(postData[0].section, 1, postData, "TEXT", "title").value.replace(stripHTMLreg, ""),
                    "image": [
                        window.location.protocol + "//" + window.location.host + EXTERNAL_GATEWAY_URL_PREFIX +
                        getDataBySectionAndWidget(postData[0].section, 1, postData, "PHOTO").value,
                    ],
                    "datePublished": datePublished,
                    "dateModified": dateModified,
                    "author": {
                        "@type": "Organization",
                        "name": BRAND
                    },
                    "publisher": {
                        "@type": "Organization",
                        "name": BRAND,
                        "logo": {
                            "@type": "ImageObject",
                            "url": window.location.protocol + "//" + window.location.host + "/img/logo-1.png"
                        }
                    }
                };
                this.setState({ jsonLdData: JSON.stringify(jsonLdData) });
            } else {
                const jsonLdData = {
                    "@context": "https://schema.org/",
                    "@type": "ItemPage",
                    "@graph": [
                        {
                            "@type": "Organization",
                            "url": window.location.protocol + "//" + window.location.host,
                            "logo": window.location.protocol + "//" + window.location.host + "/img/logo-1.png"
                        },
                        {
                            "@type": "BreadcrumbList",
                            "itemListElement": this.generateBreadCrumbsForJsonLd(nextProps.navTabs)
                        }
                    ]
                }
                this.setState({ jsonLdData: JSON.stringify(jsonLdData) });
            }
        }

        if (nextProps.selectedMenuItem !== this.props.selectedMenuItem) {
            this.setState({
                selectedMenuItem: nextProps.selectedMenuItem,
                selectedItem: this.props.productData && Object.keys(this.props.productData).length ? this.props.productData : nextProps.selectedMenuItem
            });
        }
    }

    checkIsPromo() {
        const productSizes = this.props.productData && this.props.productData.sizes || [];

        return productSizes.some((item) => item.promotion)
    }

    generateBreadCrumbsForJsonLd(navTabs) {
        const breadcrumbList: any = [];
        let breadcrumbName = "";
        let position = 1;
        if (navTabs.length) {
            navTabs.map(item => {
                if (item.url) {
                    breadcrumbName += item.name;
                    breadcrumbList.push({
                        "@type": "ListItem",
                        "position": position++,
                        "name": breadcrumbName,
                        "item": window.location.protocol + "//" + window.location.host + item.url
                    });
                    breadcrumbName = "";
                } else {
                    breadcrumbName += breadcrumbName ? ` > ${item.name} > ` : `${item.name}`;
                }
            });
        }
        if (breadcrumbList.length) {
            // breadcrumbData = {
            //     "@context": "https://schema.org",
            //     "@type": "BreadcrumbList",
            //     "itemListElement": breadcrumbList
            // };
            return breadcrumbList;
        } else {
            return breadcrumbList.push({ "@type": "ListItem", "position": 1, "name": "Home", "item": window.location.protocol + "//" + window.location.host })
        }
    }

    generateFAQDataForJsonLd(data: any = []) {
        let response: any = [];
        data.map(item => {
            item.value = item.value ? item.value.replace("<p>", "").replace(/<\/p>([^<\/p>]*)$/, '$1') : "";
            const valueArray = item.value.split("<b>");
            // for(let i=1; i < valueArray.length; i++) {
            const length = valueArray.length > 3 ? 3 : valueArray.length;
            for (let i = 1; i < length; i++) {
                const el = valueArray[i].split("</b>");
                if (el.length) {
                    response.push({
                        "@type": "Question",
                        "name": el[0],
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": el[1]
                        }
                    })
                }
            }
        })

        response = response.length ? {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": response
        } : {};
        return response;
    }


    setMenuItemByPageKey = (pageKey, menuList) => {
        const selectedMenuItem = menuList.find((item) => item.key === pageKey);
        this.setState({
            selectedMenuItem,
            selectedItem: this.props.productData && Object.keys(this.props.productData).length ? this.props.productData : selectedMenuItem
        })

    };

    render() {

        const { selectedMenuItem, jsonLdData, selectedItem = {} } = this.state;

        const { data, postData } = this.props;
        let title = selectedItem.title ? selectedItem.title : selectedItem.name ? selectedItem.name : selectedItem.metaTitle;
        let description = selectedItem.metaDescription ? selectedItem.metaDescription : selectedItem.description;
        let metaDescription = selectedItem.metaDescription ? selectedItem.metaDescription : '';
        let productId = selectedItem.productId;
        if (selectedItem && (selectedItem.productType === 'C' || selectedItem.productType === 'S')) {
            title = selectedItem.metaTitle ? selectedItem.metaTitle : selectedItem.title ? selectedItem.title : selectedItem.name
        }
        if (postData && postData.length) {
            const titleVal = getDataBySectionAndWidget(postData[0].section, 1, postData, "TEXT", "title").value;

            const descriptionVal = getDataBySectionAndWidget(postData[0].section, 1, postData, "TEXT", "Subtitle").value;
            title = titleVal && typeof titleVal === 'string' ? titleVal.replace(stripHTMLreg, "") : "";
            description = descriptionVal && typeof descriptionVal === 'string' ? descriptionVal.replace(stripHTMLreg, "") : ""
        }

        return (
            <>
                {selectedItem && Object.keys(selectedItem).length ?
                    <Helmet>
                        <title>{title}</title>

                        {getDataBySectionAndWidget(1, 1, data, "PHOTO", "banner").value || getDataBySectionAndWidget(1, 1, data, "PHOTO", "banner-promo").value ?
                            <meta name="thumbnail" content={`${BASE_URL || ROOT_URL}${EXTERNAL_GATEWAY_URL_PREFIX}${getDataBySectionAndWidget(1, 1, data, "PHOTO", "banner").value || getDataBySectionAndWidget(1, 1, data, "PHOTO", "banner-promo").value}`}
                            /> : null}
                        {getDataBySectionAndWidget(1, 1, data, "PHOTO", "banner").value || getDataBySectionAndWidget(1, 1, data, "PHOTO", "banner-promo").value ?
                            <meta name="og:image" content={`${BASE_URL || ROOT_URL}${EXTERNAL_GATEWAY_URL_PREFIX}${getDataBySectionAndWidget(1, 1, data, "PHOTO", "banner").value || getDataBySectionAndWidget(1, 1, data, "PHOTO", "banner-promo").value}`}
                            /> : null}
                        {description && <meta name="og:description" content={description.replace(stripHTMLreg, "")} />}
                        {metaDescription && <meta name="description" content={metaDescription.replace(stripHTMLreg, "")} />}

                        {selectedItem.metaTitle && <meta property="og:title" content={selectedItem.metaTitle} />}

                        {this.props.productData && Object.keys(this.props.productData).length ?
                            <link
                                rel="canonical"
                                href={`${ROOT_URL}${getLangPrefix()}${this.props.productData.canonicalMenu || selectedMenuItem.canonicalUrl}/${this.props.productData.canonicalUrl}`}
                            /> : selectedMenuItem && selectedMenuItem.canonicalUrl ?
                                <link
                                    rel="canonical"
                                    href={`${BASE_URL || ROOT_URL}${getLangPrefix()}${selectedMenuItem.canonicalUrl}`}
                                /> : ""
                        }
                        {
                            jsonLdData &&
                            <script type="application/ld+json">{`
                            ${jsonLdData}
                        `}</script>
                        }
                    </Helmet>
                    : ""
                }
            </>
        );

    }
}

const mapStateToProps = (state: any): Props => {
    return {
        menuList: getMenu(state),
        brandDetails: getBrandDetail(state)
    }
};

export default connect(mapStateToProps)(PageMetaData);
