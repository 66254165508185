/* eslint-disable */
import parse from "html-react-parser";
import axios from "axios";
import i18next from "i18next";
import { BASE_API_GATEWAY } from "../utils/constants/service";
import {
    STORAGE_BASKET_INDEX,
    PRODUCT_MAX_QUANTITY,
    VALIDATE_DISCCOUNT,
    DELIVERY_PRICE,
    BRAND,
    BRAND_ID,
    BROWSER_KEY,
    SHIPMENT,
    COUNTRY,
    APPOINTMENT_MAX_DAYS,
    PRODUCT_ITEM_TYPES,
    LOCAL_STORAGE_VARIABLES,
    DEFAULT_CURRENCY,
    COOKIE_TRACKING,
    PROMOTION_CLUSTER,
    ALT_BRAND_ID,
    LANGUAGE,
    PROMOTION_DISCOUNT,
    GTM_TAG,
    PRIVATE_DISCOUNT
} from "../utils/constants/variables";
import { store } from '../index';
import {
    getMenu,
    getProductClusterBarcodes,
    getProductClusterPackBarcodes
} from "../layouts/main/header/redux/selectors";
import { getBasket } from "../modules/addToCart/redux/selectors";
import { getBrandDetail } from "../layouts/main/header/redux/selectors";
import { getCouponSpecialDiscount, getDiscountByCluster } from "./discountHelper";
import { getDiscount, getPromotionDiscount } from "../modules/basket/redux/selectors";
import { getClusterTotals, getPromotionDiscountValue } from "./promotionDiscountHelper";

let jwt = require('jsonwebtoken');

export const isLoggedin = () => {
    const token = localStorage.getItem('token');

    if (token) {
        const decodedJwt = jwt.decode(token);
        if (decodedJwt) {
            const expirationTime = decodedJwt.exp;
            const currentTime = + new Date();
            if (expirationTime && expirationTime * 1000 > currentTime) {
                return true;
            }
        }
    }
    localStorage.removeItem('token')
    return false;
};

export const resetDatalayer = () => {
    if (window.dataLayer !== undefined && window.google_tag_manager !== undefined) {
        window.dataLayer.length = 0;
        const gtmContainerReg = GTM_TAG;
        window.google_tag_manager[gtmContainerReg].dataLayer.reset();
        var gtm = window.google_tag_manager[GTM_TAG];
        gtm.dataLayer.reset();
    }
}

export const dataLayerClick = (productObj, label) => {
    localStorage.selectedProductBrandId = productObj.altBrandId || productObj.brandId;
    window['dataLayer'] = Array.isArray(window['dataLayer']) ? window['dataLayer'] : [];
    window['dataLayer'].push({
        // 'event': 'productClick',
        'ecommerce': {
            'click': {
                'actionField': { 'list': 'Search Results' },
                'products': [{
                    'name': productObj && productObj.name,
                    'id': productObj && productObj.productId,
                    'price': !productObj.listPriceAfterDiscount ? productObj.startingPrice : productObj.listPriceAfterDiscount,
                    'brand': BRAND,
                    'category': label && label,
                    'position': productObj && productObj.ranking
                }]
            }
        },
    });
};

export const dataLayerPush = (orderId, email) => {
    if (!Array.isArray(window.dataLayer)) window.dataLayer = [];
    const basket = getLocalStorageObject("basket");
    const { totalPrice } = getBasketData(basket);
    const freight_cost = decryptCryptr("freight_cost");
    const sha1 = require('sha1');
    const hashEmail = sha1(email)
    let product = [];
    basket && Object.keys(basket).length && Object.keys(basket).map(item => {
        const basketItem = basket[item];
        return basketItem.sizes && Object.keys(basketItem.sizes).length && Object.keys(basketItem.sizes).map(elem => {
            const sizeItem = basketItem.sizes[elem];
            return product.push({
                Id: sizeItem.productReference ? +sizeItem.productReference : +basketItem.Reference,
                name: basketItem.name,
                price: sizeItem.startingPrice,
                size: sizeItem.size,
                quantity: sizeItem.quantity
            })
        })

    });

    window['dataLayer'].push({
        event: 'transactionComplete',
        // pageCategory: 'orderconfirmation',
        ecommerce: {
            currencyCode: "EUR",
            purchase: {
                actionField: {
                    id: orderId,
                    revenue: totalPrice,
                    tax: Number((totalPrice * 0.2 / 1.2).toFixed(2)),
                    shipping: Number(freight_cost)
                },
                products: product
            }
        },
        userId: hashEmail,
        userLogged: 1,
    });
};

export const getBrandName = (id) => {
    const reduxState = store.getState();
    const detail = getBrandDetail(reduxState) || {};
    const { brands = {} } = detail;
    const brand = Object.values(brands).find(br => +br.brandId === +id)
    return brand && brand.name || ''
}

export const getConvertedItemDataForGA4 = (menuItem, item, quantity = 1, size = undefined) => {
    const reduxState = store.getState();
    const detail = getBrandDetail(reduxState);
    const menu = getMenu(reduxState);
    const itemID = size && size.productReference || (item && item.sizes && item.sizes[0].productReference || '') || item && item.productId || 0
    const discount = size && size.listPriceAfterDiscount
        ? size.startingPrice - size.listPriceAfterDiscount
        : (item.sizes
            ? item.sizes[0].listPriceAfterDiscount
                ? item.sizes[0].startingPrice - item.sizes[0].listPriceAfterDiscount
                : 0
            : item.listPriceAfterDiscount
                ? item.startingPrice - item.listPriceAfterDiscount
                : 0);
    const price = size
        ? size.listPriceAfterDiscount || size.oldPrice || size.startingPrice
        : item.sizes
            ? item.sizes[0].listPriceAfterDiscount || item.sizes[0].oldPrice || item.sizes[0].startingPrice
            : item.listPriceAfterDiscount || item.oldPrice || item.startingPrice
    const priceAfterDiscount = size
        ? size.listPriceAfterDiscount || size.oldPrice || size.startingPrice
        : item.listPriceAfterDiscount || item.oldPrice || item.startingPrice
    const vDiscount = getLocalStorageObject(VALIDATE_DISCCOUNT)
    const { discountLabel, value = 0, couponType } = vDiscount
    const calcDiscount = tooFixed(discount + (couponType && couponType === 'PERCENT' ? ((+priceAfterDiscount > 0 ? +priceAfterDiscount : +price) / +value) : 0), 2)
    const promName = detail && detail.storePromotion && detail.storePromotion.promotionName || ''
    let levels = null
    if (menuItem && Object.keys(menuItem).length) {
        levels = getMenuLevels(menu, menuItem)
    }

    return {
        item_id: itemID,
        item_name: item.name || '',
        affiliation: detail.name || '',
        coupon: discountLabel || '',
        discount: calcDiscount,
        index: menuItem && menuItem.ranking || 0,
        item_brand: item.brandName ? item.brandName : item.brandId ? getBrandName(item.brandId) : '',
        ...(levels && levels[0] !== 'undefined' && { item_category: levels[0] }),
        ...(levels && levels[1] !== 'undefined' && { item_category2: levels[1] }),
        ...(levels && levels[2] !== 'undefined' && { item_category3: levels[2] }),
        ...(levels && levels[3] !== 'undefined' && { item_category4: levels[3] }),
        ...(levels && levels[4] !== 'undefined' && { item_category5: levels[4] }),
        item_list_id: '',
        item_list_name: 'Products',
        item_variant: size && size.color || item.color || '',
        location_id: detail.storeCode || '',
        price,
        quantity: +quantity,
        shipping_tier: "Ground",
        ...(promName && { promotion_name: promName })

    }
}

export const getMenuLevels = (menu, menuItem) => {

    function findElementById(array, parentId) {
        for (const item of array) {
            if (item.menuId === parentId) {
                return item;
            }
            if (item.children) {
                const childResult = findElementById(item.children, parentId);
                if (childResult) {
                    return childResult;
                }
            }
        }
        return null;
    }

    function findHighestWithoutParentId(array, startingElement) {
        const levels = {}
        let currentElement = startingElement;
        levels[currentElement.level] = currentElement.label
        let parentElement = findElementById(array, currentElement.parent);
        while (parentElement) {
            currentElement = parentElement;
            levels[parentElement.level] = parentElement.label
            parentElement = findElementById(array, currentElement.parent);
        }
        return levels;
    }

    return findHighestWithoutParentId(menu, menuItem);
};

export const getMenuItemByMenuId = (menuList = [], key = "") => {
    let menuItem = {}
    if (key && menuList && menuList.length) {
        menuItem = findNodeByProperty(menuList, key, "menuId");
        if (menuItem && Object.keys(menuItem).length && menuItem.children && menuItem.key !== key) {
            menuItem = findNodeByProperty(menuItem.children, key, "menuId");
        }
    }
    return menuItem;
};

export const getMenuItemByKey = (menuList = [], key = "") => {
    let menuItem = {}
    if (key && menuList && menuList.length) {
        menuItem = findNodeByProperty(menuList, key, "key");
        if (menuItem && Object.keys(menuItem).length && menuItem.children && menuItem.key !== key) {
            menuItem = findNodeByProperty(menuItem.children, key, "key");
        }
    }
    return menuItem;
};

export const getMenuItemByMenuIdElem = (menuList = [], menuId = null) => {
    for (const item of menuList) {
        if (item.menuId === menuId) {
            return item;
        }
        if (item.children) {
            const result = getMenuItemByMenuIdElem(item.children, menuId);
            if (result) {
                return result;
            }
        }
    }
    return null;
};

export const getMenuItemByProductId = (basket = {}, productId) => {
    let currentItem = null;

    const checkItem = (item) => {
        if (item.productId === productId) {
            currentItem = item;
            return true;
        }

        if (item.sizes) {
            const findedItem = item.sizes.find(it => it.productId === productId);
            if (findedItem) {
                currentItem = item;
                return true;
            }
        }

        return false;
    };

    const checkBasket = (basket) => {
        for (const key in basket) {
            if (key !== 'store') {
                const resultItem = checkItem(basket[key]);
                if (resultItem) return;

                if (currentItem === null && basket[key].children) {
                    checkBasket(basket[key].children);
                }
            }
        }
    };

    checkBasket(basket);
    return currentItem;
};

export const getPackDiscountPrice = (name, product, packID) => {
    let parentElementQuantity = 0;
    let childElementQuantity = 0;
    let childElementStartingPrice = 0;
    let childElementDiscountPrice = 0;
    let totalChildDiscountPrice = 0;
    let totalChildPrice = 0;
    let currentItemPrice = 0;

    if (product.packParent) {
        const packElement = getLocalStorageObject(name)[product.packParent];
        const pack = getLocalStorageObject(name)[packID];
        packElement && Array.isArray(packElement.sizes) && packElement.sizes.forEach(item => {
            parentElementQuantity += item.quantity;
            currentItemPrice = item.quantity;
        });

        pack && Array.isArray(pack.sizes) && pack.sizes.forEach(item => {
            childElementQuantity += item.quantity;
            childElementStartingPrice += item.startingPrice;
            childElementDiscountPrice = item.startingPrice - ((item.startingPrice * parseInt(getLocalStorageObject(name)[packID].discountDetail)) / 100)

        });

        if (parentElementQuantity > childElementQuantity) {
            totalChildDiscountPrice = parentElementQuantity * childElementDiscountPrice;
        } else if (parentElementQuantity < childElementQuantity) {
            const count = childElementQuantity - parentElementQuantity;
            totalChildPrice = count * childElementStartingPrice;
        }

    }
    return {
        parentElementQuantity,
        childElementQuantity,
        totalChildDiscountPrice,
        totalChildPrice,
        childElementStartingPrice,
        currentItemPrice
    }

};

export const getProductPack = (name, id) => {
    const packElem = getLocalStorageObject(name)[id];
    let quantity = 0;
    Array.isArray(packElem.sizes) && packElem.sizes.forEach(item => {
        quantity += item.quantity
    });
    let packID = [];
    if (Array.isArray(packElem.pack)) {
        packElem.pack.forEach(item => {
            const elem = {
                productId: item.parentProductId,
                prSize: item.productId,
                quantity,
            };
            packID.push(elem)
        })
    }
    return packID
};
export const afterOrderUpdateLocalStorage = (name, data) => {
    let existing = JSON.parse(localStorage.getItem(name));

    Object.keys(existing).map((item) => {
        return existing[item].sizes && existing[item].sizes.map((elem) => {
            const res = data.find(item => item.productId === elem.productId);

            if (res && res.listPrice) {
                elem.listPriceAfterDiscount = res.listPrice;
                return elem
            } else {
                return elem
            }
        })

    });
    localStorage.setItem(name, JSON.stringify(existing));
};
export const addToLocalStorage = function (name, key, value, setAsQuantity = false) {
    if (value && value.size && !value.size.oldPrice) {
        value.size.oldPrice = value.size.startingPrice
    }

    value && value.size && delete value.size.storeInventories;
    value && value.size && delete value.size.inventories;
    let existing = localStorage.getItem(name), addedToStorage = true;

    try {
        existing = existing ? JSON.parse(existing) : {}
    } catch (e) {
        existing = {}
    }

    if (name === STORAGE_BASKET_INDEX) {
        if (existing[key] && Object.entries(existing[key]).length) {
            const sizeProductId = parseInt(value.size.productId);
            const sizeIndexByProductId = getIndexByValue(existing[key].sizes, sizeProductId, "productId");

            if (existing[key].sizes.length && sizeIndexByProductId !== -1) {
                const qty = parseInt(value.size.quantity);
                existing[key].sizes[sizeIndexByProductId].quantity = qty;
                if (existing[key].sizes[sizeIndexByProductId].quantity > PRODUCT_MAX_QUANTITY) {
                    addedToStorage = false
                }
            } else {
                existing[key].sizes.push(value.size)
            }
        } else {
            const { size, ...data } = value;
            data.sizes = [size];
            existing[key] = data;
        }
    }

    if (addedToStorage) {
        localStorage.setItem(name, JSON.stringify(existing));
    }
    return addedToStorage;
};

export const getLocalStorageObject = (name) => {
    const existing = localStorage.getItem(name);
    if (!existing) {
        return {}
    }
    try {
        return JSON.parse(existing)
    } catch (e) {
        return {}
    }
};

export const removeFromStorage = function (name, key, sizeProductId) {
    let existing = localStorage.getItem(name);
    existing = existing ? JSON.parse(existing) : {};

    if (existing[key] && Object.entries(existing[key]).length) {
        const sizeIndexByProductId = getIndexByValue(existing[key].sizes, sizeProductId, "productId");
        if (existing[key].sizes.length && sizeIndexByProductId !== -1) {
            delete existing[key].sizes.splice(sizeIndexByProductId, 1);
            if (!existing[key].sizes.length) {
                delete existing[key];
            }
        }
        if ((Object.keys(existing).length === 1 && Object.keys(existing)[0] === "store") || Object.keys(existing).length === 0) {
            localStorage.removeItem(STORAGE_BASKET_INDEX)
        } else {
            localStorage.setItem(name, JSON.stringify(existing));
        }
    }

};

export const findItem = (menuList, levels) => {
    let found;
    if (typeof menuList == 'object') {
        found = menuList.find(item => {
            return item.key.split('_').join('-') === levels[0]
        });
        let level = levels.slice(1);
        if (found && found.children && level.length > 0) {
            return findItem(found.children, level)
        }
    }
    return found;
};

export const getData = (key, type = "text", data) => {
    let result;
    const itemByKey = data.length ? data.find(item => item.name === key && (type === "text" ? item.type.toUpperCase() === "TEXT" : item.type.toUpperCase() === "PHOTO")) : "";
    switch (type) {
        case "text":
            result = itemByKey ? itemByKey.value : "";
            break;
        case "image":
            result = itemByKey ? (BASE_API_GATEWAY + itemByKey && itemByKey.value) : "";
            break;
        default:
            result = "";
    }
    return result;
};


export const getDataBySectionAndWidget = (section, widget, data = [], type = "", name = "") => {
    let items = {};
    if (name === "") {
        items = data.length && data.find(item => type ? item.section === section && item.widget === widget && item.type.toUpperCase() === type : item.section === section && item.widget === widget);
    } else {
        items = data.length && data.find((item) => {
            if (type && item.name) {
                return item.section === section && item.widget === widget && item.type.toUpperCase() === type && item.name.toUpperCase() === name.toUpperCase();
            }

            if (item.name) {
                return item.section === section && item.widget === widget && item.name.toUpperCase() === name.toUpperCase();
            }

            return item.section === section && item.widget === widget
        });
    }

    return items || {};
};


export const getVisuals = (key, value, data, type) => {
    let visuals;
    const val = value.replace(/(<([^>]+)>)/ig, "");
    if (data && data.length) {
        const item = data.find(item => item[key].toUpperCase() === val.toUpperCase());
        visuals = item && item.visuals ? item.visuals[0] : '';
    }
    if (visuals && visuals[type]) {
        return type.toUpperCase() === "PHOTO" ? BASE_API_GATEWAY + visuals[type] : visuals[type];
    }
    return '';
};

export const getItemMenu = (type, key, data) => {
    if (data && data.length) {
        return data.find((item) => {
            return (item.menuType.toUpperCase() === type.toUpperCase() && item.key === key)
                || (item.children && (item.children.length) && getItemMenu(type, key, item.children))
                || (item.altUrl1 === 'size' && key !== 'cookie' && item.key !== 'footer_3') || (item.altUrl1 === "collection")
        });
    }
    return '';
};

export const getProductFullUrl = (location, canonicalUrl = "") => {
    let currentLocation = location.pathname.split('/');
    let levels = currentLocation.slice(1);
    levels.splice(-1, 1);
    levels.push(canonicalUrl);
    return "/" + levels.join("/");
};

export const parseData = (data) => {
    const regex = /(<([^>]+)>)/ig;
    return parse(data.replace(regex, ''));
};

export const makeThousandFormat = (price) => {
    return new Intl.NumberFormat('en-EN', { currency: 'EUR' }).format(price);
}

export const getCurrency = (currency = "") => {
    let result = "";
    switch (currency) {
        case "EUR":
            result = "€";
            break;
        default:
            result = "€";
            break;
    }
    return result;
};

export const getRouteByMenuKey = (menuList = [], key = "") => {
    let route = "";
    let label = ""
    if (key && menuList && menuList.length) {
        let menuItem = findNodeByProperty(menuList, key, "key");

        if (menuItem && Object.keys(menuItem).length && menuItem.children && menuItem.key !== key) {
            menuItem = findNodeByProperty(menuItem.children, key, "key");
        }

        if (menuItem && Object.keys(menuItem).length && menuItem.children && menuItem.key !== key) {
            menuItem = findNodeByProperty(menuItem.children, key, "key");
        }

        if (menuItem && Object.keys(menuItem).length && menuItem.canonicalUrl) {
            route = `${getLangPrefix()}${menuItem.canonicalUrl}`;
            label = menuItem.label;
        }
    }
    return { route, label };
};

export const getRouteByAltUrl1 = (menuList = [], altUrl1 = "") => {
    const menu = menuList.find((menu) => menu.altUrl1 === altUrl1) || {};
    return menu.canonicalUrl ? `${getLangPrefix()}${menu.canonicalUrl}` : '/';
};

export const replaceComma = (price) => {
    return Number(price).toFixed(2).replace(".", ",");
};

export const isFloat = (n) => {
    return Number(n) === n && n % 1 !== 0;
};

export const tooFixed = (num, fixed) => {
    var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
    return +num.toString().match(re)[0];
}

const getIndexByValue = (array = [], value = "", key = "") => {
    for (var i = 0; i < array.length; i++) {
        if (array[i][key] === value) return i;
    }
    return -1;
};

export const findNodeByProperty = (tree, filter, property = "id") =>
(tree.find((node) =>
    (node[property] === filter) || (node[property] !== filter && node.children && (node.children.length) && findNodeByProperty(node.children, filter, property))
));

export const getOpeningHours = (str = "") => {
    let res = [];
    str = str.replace(/^(;*)/, "").replace(/(;*)$/, "");
    if (str) {
        res = str.split(";");
    }
    return res;
};

export const formatOpeningHours = (hours = "") => {
    let item = hours.split("|");
    const langOpeningFr = getLang() === "fr" ? " et " : getLang() === "en" ? " and " : " e ";
    let hour = '';
    if (item.length > 2) {
        for (let i = 0; i < item.length; i++) {
            if (i % 2 === 0) {
                hour += `${item[i]}h-`.replace(/^(0*)/, "");
            } else {
                hour += `${item[i]}h`.replace(/^(0*)/, "");
            }
            if (i !== 0 && i % 2 === 1 && i < item.length - 1) {
                hour += `${langOpeningFr}`
            }
        }
    } else {
        hour = `${item[0].replace(/^(0*)/, "")}h - ${item[1].replace(/^(0*)/, "")}h`;
    }

    return hour
};

export const formatPhone = (str = "") => {
    let phone = str.replace(/^.{0,3}/, "0").match(/.{1,2}/g);
    return phone.join(" ");
};

export const getCatalogueParentMenuId = (menu, menuList, parenKey) => {
    if (menu && menu.key && menuList && menuList.length) {
        let parent = menuList.find(item => item.key === parenKey);
        if (parent && parent.children) {
            parent = getItemMenu("MENU", menu.key, parent.children)
        }
        if (parent && parent.children) {
            parent = getItemMenu("MENU", menu.key, parent.children)
        }
        if (parent && parent.children) {
            parent = getItemMenu("MENU", menu.key, parent.children)
        }
        return parent && parent.menuId
    }

    return "";
};

export const getDateTimestamp = (timestamp) => {
    if (!timestamp) {
        return "";
    }
    const date = new Date();
    date.setTime(timestamp);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${day}/${month}/${year}`

};

export const getIntlDate = (num) => {
    return new Intl.DateTimeFormat("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" }).format(num)
}

export const getDateNow = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
    return { day, month, year, hours, minutes, seconds };

};

export const getPromotionValue = (data, totalPrice, quantity) => {
    const ruleType = data && data.ruleType || "";
    switch (ruleType) {
        case "AMOUNT":
        case "VALUE":
            return totalPrice >= data.threshold ? data.discount_1 : false;
        case "QUANTITY":
            return quantity >= data.quantity ? data.discount_1 : false;
        case "PERCENTAGE":
            if (quantity === 1) {
                return data.discount_1;
            }
            if (quantity === 2) {
                return [data.discount_2, data.discount_1];
            }
            if (quantity === 3) {
                return [data.discount_3, data.discount_2, data.discount_1];
            }
            if (quantity > 3) {
                return getDiscountList(data, quantity);
            };
        case "PACK":
            return [data.discount_1, data.discount_2];
    }
}


export const getBasketData = (orders = {}, discount = getLocalStorageObject(VALIDATE_DISCCOUNT)) => {
    let basket = {}
    let actualBasket = { ...orders };
    const validateDiscount = discount.value || 0;
    const validateDiscountType = discount.couponType || "";
    const productCluster = discount.productCluster || null;
    let price = 0;
    let totalPrice = 0;

    let totalGiftCardPrice = 0;
    let totalQuantity = 0;
    let quantity = 0;
    let livraison = SHIPMENT;
    let oldPrice = 0;
    let pack = [];
    let isPromoDiscountApplyed = false;
    let discountedSum = 0;
    let totalCouponDiscount = 0;

    const reduxState = store.getState();
    const promotionDiscount = getPromotionDiscount(reduxState) || {};
    const packBarcodes = getProductClusterPackBarcodes(reduxState)

    if (promotionDiscount) {
        anullerSpecialDiscount(orders);
    };

    if (orders && Object.keys(orders).length) {
        Object.keys(orders).forEach((item) => {
            if (orders[item].sizes && orders[item].sizes.length) {
                basket = { ...basket, [item]: orders[item] }
            }
        })
    }

    if (Object.keys(basket).length) {
        for (let i in basket) {
            if (basket[i] && basket[i].pack && Array.isArray(basket[i].pack)) {
                let count = 0;
                const currentBasket = getLocalStorageObject(STORAGE_BASKET_INDEX);
                basket[i].sizes.forEach(elem => {
                    count += elem.quantity;
                })
                basket[i].pack.forEach((item) => {
                    if (currentBasket[item.parentProductId] && Array.isArray(currentBasket[item.parentProductId].sizes)) {
                        currentBasket[item.parentProductId].sizes.forEach(sizeitem => {
                            count = sizeitem.quantity - count
                        })
                    }
                    pack.push({
                        item,
                        totalCount: count > 0 ? count : 0
                    })
                })
            }
        }
    }

    if (Object.keys(basket).length) {
        Object.keys(basket).forEach(i => {
            const itemPrice = basket[i].sizes && basket[i].sizes && basket[i].sizes.reduce((total, curent) => {
                const packItem = pack.find(item => item.item.productId === curent.productId);
                totalQuantity += curent.quantity;
                if (packItem) {
                    let sum = total + curent.quantity * ((curent.listPriceAfterDiscount || curent.startingPrice) - (packItem.item.startingPrice * (Math.abs(parseInt(packItem.item.discountDetail)) / 100)));
                    return sum + packItem.totalCount * packItem.item.startingPrice
                } else {
                    const price = curent.quantity * (curent.listPriceAfterDiscount || curent.startingPrice)
                    if (curent.itemType === PRODUCT_ITEM_TYPES.GFC) {
                        totalGiftCardPrice += price
                    }
                    return total + price
                }
            }, 0);

            const oldItemPrice = basket[i].sizes && basket[i].sizes.reduce((total, curent) => {
                const packItem = pack.find(item => item.productId === curent.productId);
                if (packItem) {
                    let sum = total + curent.quantity * ((curent.oldPrice || curent.startingPrice) - (packItem.item.startingPrice * (Math.abs(parseInt(packItem.item.discountDetail)) / 100)))
                    return sum + packItem.totalCount * packItem.item.startingPrice
                } else {
                    return total + curent.quantity * (curent.oldPrice || curent.startingPrice)
                }
            }, 0)

            oldPrice += oldItemPrice
            price += itemPrice;
        })

        if (price >= DELIVERY_PRICE || (orders["store"] && orders["store"]['storeDelivery'] === "STO")) {
            livraison = 0;
        }

        if (!decryptCryptr("freight_cost") || +decryptCryptr("freight_cost") !== livraison) {
            encrypt("freight_cost", livraison);
        }

        quantity = Object.keys(basket).length;
        totalPrice = price + livraison;

        if (productCluster) {


            if (validateDiscountType === "PERCENT") {
                const totalValueDiscount = getDiscountByCluster(discount, totalPrice, price);

                if (totalGiftCardPrice === 0) {
                    totalCouponDiscount = totalValueDiscount;
                    totalPrice -= totalValueDiscount;
                } else if (totalGiftCardPrice === price) {
                    totalPrice = price;
                } else {
                    const sum = price - totalGiftCardPrice;
                    totalCouponDiscount = totalValueDiscount;
                    totalPrice = sum - totalValueDiscount + livraison + totalGiftCardPrice;
                }
            } else if (totalPrice > validateDiscount) {
                totalPrice = totalPrice - validateDiscount;
                totalCouponDiscount = validateDiscount;
            }

            discountedSum = getPromotionDiscountValue(promotionDiscount, actualBasket) || 0;
            if (discountedSum && discountedSum > 0) {
                totalPrice = totalPrice - discountedSum;
                isPromoDiscountApplyed = true;
            }

        } else {
            discountedSum = getPromotionDiscountValue(promotionDiscount, actualBasket) || 0;
            if (discountedSum && discountedSum > 0) {
                totalPrice = totalPrice - discountedSum;
                isPromoDiscountApplyed = true;
            }

            if (validateDiscountType === "PERCENT") {
                const totalValueDiscount = getDiscountByCluster(discount, totalPrice, price);

                if (totalGiftCardPrice === 0) {
                    totalCouponDiscount = totalValueDiscount;
                    totalPrice -= totalValueDiscount;
                } else if (totalGiftCardPrice === price) {
                    totalPrice = price;
                } else {
                    const sum = price - totalGiftCardPrice;
                    totalCouponDiscount = totalValueDiscount;
                    totalPrice = sum - totalValueDiscount + livraison + totalGiftCardPrice;
                }
            } else if (totalPrice > validateDiscount) {
                totalPrice = totalPrice - validateDiscount;
                totalCouponDiscount = validateDiscount;
            }
        }


        if (oldPrice) {
            oldPrice += livraison;
        }
    }

    if (!quantity) {
        livraison = 0;
    }
    const data = {
        price,
        totalPrice,
        quantity,
        livraison,
        totalQuantity,
        oldPrice,
        totalGiftCardPrice,
        discountedSum,
        totalCouponDiscount,
        isPromoDiscountApplyed
    }

    const totalDValue = getFullCouponSpecialDiscount(actualBasket, data, discount, promotionDiscount, packBarcodes)
    data.totalPrice = totalDValue + livraison;

    return data
};

export const getOrderByStatus = (status = "", orders = []) => {
    return orders && orders.length ? orders.find(item => item.orderStatus && item.orderStatus.code === status) : {}
};

export const redirectPage = () => {
    window.location.assign(getLangPrefix() + "/503")
};

export function detectmob() {
    if (navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
    ) {
        return navigator.userAgent.match;
    } else {
        return "";
    }
}

export function getBrowserPlatform() {
    const { userAgent = '' } = navigator;
    const isMobile = /Android|iPhone|iPod|BlackBerry|Windows Phone/i.test(userAgent);
    const isTablet = /iPad|tablet|kindle|playbook|silk/i.test(userAgent);

    if (isMobile) {
        return 'mobile';
    } else if (isTablet) {
        return 'tablet';
    }

    return 'desktop'
}

export function getBrowserOs() {
    const { userAgent = '' } = navigator;
    const osPattern = new RegExp('Linux|Windows|Mac|Android', 'g');
    const result = userAgent.match(osPattern);

    return result && result.length ? result[result.length - 1] : '';
}

export const clickStream = (menuId, productUrl = null, productId = null) => {
    const url = (window.location !== window.parent.location) ? document.referrer : document.location.href;
    const { detect } = require('detect-browser');
    const browser = detect();
    const checkUSer = localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : null;
    const browserOS = getBrowserOs();

    const headers = {
        headers: {
            Authorization: checkUSer,
            'Content-Type': 'application/json'
        }
    };
    const body = {
        brandId: BRAND_ID,
        browserType: detectmob() ? browser.name + "-mobile" : browser.name,
        clickTime: Date.now(),
        ipAddress: decryptCryptr("ip"),
        menuId: menuId,
        product: productUrl,
        orginUrl: url,
        recommended: false,
        productId: productId,
        browserId: decryptCryptr("browserId"),
        browserOS,
        browserPlatform: getBrowserPlatform(),
        browserCountry: localStorage.country
    };

    axios.post(`${BASE_API_GATEWAY}/api/external/clickstream/create`, JSON.stringify(body), headers).then((res) => "hi");
};

export const getLang = () => {
    let lang = LANGUAGE;
    if (i18next.language === "en" || i18next.language === "it") {
        lang = i18next.language;
    }
    localStorage.setItem("language", lang);
    return lang;
};

export const getApiPrefix = () => {
    const lang = getLang();
    return `${lang}_${lang.toUpperCase()}`;
};

export const getLangPrefix = () => {
    return `/${getLang()}`;
}

export const getFilterValue = (value) => {
    if (typeof value === "string") {
        return value.replace(/"|''|^'|\s+'$|'$/g, "");
    } else {
        return value
    }
};

export const emailIsValid = (email) => {
    const reg = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/;
    return reg.test(email)
};

export const encrypt = (key, value) => {
    const Cryptr = require('cryptr');
    const cryptr = new Cryptr(BROWSER_KEY);
    return localStorage.setItem(key, cryptr.encrypt(value))
};

export const decryptCryptr = (key) => {
    const Cryptr = require('cryptr');
    const cryptr = new Cryptr(BROWSER_KEY);
    const value = localStorage.getItem(key);
    return value ? cryptr.decrypt(value) : "";
};

export const getBase64ImageFromUrl = async (imageUrl) => {
    return new Promise(async (resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', imageUrl && imageUrl, true);
        xhr.onload = function () {
            const response = xhr.responseText;
            let binary = ""
            for (let i = 0; i < response.length; i++) {
                binary += String.fromCharCode(response.charCodeAt(i) & 0xff);
            }
            const src = 'data:image/jpeg;base64,' + btoa(binary);
            resolve(src && src);
        }

        xhr.overrideMimeType('text/plain; charset=x-user-defined');
        xhr.send();
    })

};

export const getLivrasionEstimate = (storeDeliveryVariable) => {
    const date = new Date();
    const currentTimestamp = date.getTime();
    const getDayOfWeek = date.getDay();
    const nextDaysCount = getDayOfWeek === 0 ? storeDeliveryVariable === "STO" ? 2 : 8 : storeDeliveryVariable === "STO" ? 1 : 7;
    const nextDayTimestamp = currentTimestamp + getDaysMiliseconds(nextDaysCount);
    const nextDate = new Date(nextDayTimestamp);
    const getDayName = days[nextDate.getDay()];
    const getMonthName = monthNames[nextDate.getMonth()];

    return `${i18next.t(getDayName)} ${nextDate.getDate()} ${i18next.t(getMonthName)} ${nextDate.getFullYear()}`;
}

export const getGridTypeId = (data) => {

    if (!data) {
        return 0
    }

    if (Array.isArray(data.children) && data.children.length) {
        const find = data.children.find(item => item.children);
        if (find) {
            return getGridTypeId(find);
        } else {
            return data.children[0].gridTypeId || 0;
        }
    } else {
        return data.gridTypeId || 0;
    }
};

export const postBasketBody = () => {
    const basket = getLocalStorageObject("basket");
    const basketLineItems = [];
    for (const item in basket) {
        if (basket[item].sizes && basket[item].sizes.length) {
            basket[item].sizes.forEach(product => {
                basketLineItems.push({ productId: product.productId, quantity: product.quantity })
            })
        }
    }
    return basketLineItems
};

export const fingerprint = (window, screen, navigator) => {

    function checksum(str) {
        let hash = 5381,
            i = str.length;

        while (i--) hash = (hash * 33) ^ str.charCodeAt(i);

        return hash >>> 0;
    }

    function map(arr, fn) {
        let i = 0, len = arr.length, ret = [];
        while (i < len) {
            ret[i] = fn(arr[i++]);
        }
        return ret;
    }

    return checksum([
        navigator.userAgent,
        [screen.height, screen.width, screen.colorDepth].join('x'),
        new Date().getTimezoneOffset(),
        map(navigator.plugins, (plugin) => {
            return [
                plugin.name,
                plugin.description,
                map(plugin, (mime) => {
                    return [mime.type, mime.suffixes].join('~');
                }).join(',')
            ].join("::");
        }).join(';')
    ].join('###'));

};

export const monthNames = [
    'months.0', 'months.1', 'months.2',
    'months.3', 'months.4', 'months.5',
    'months.6', 'months.7', 'months.8',
    'months.9', 'months.10', 'months.11'
];
// days
export const days = [
    'days.0', 'days.1', 'days.2',
    'days.3', 'days.4', 'days.5',
    'days.6'
];
// FOR BASSSETI & ZUCCHI
export const monthNamesIT = [
    'months.0', 'months.1', 'months.2',
    'months.3', 'months.4', 'months.5',
    'months.6', 'months.7', 'months.8',
    'months.9', 'months.10', 'months.11'
];

export const discountHeader = (brandId) => {
    const headers = {
        headers: {
            brand: brandId || ALT_BRAND_ID || BRAND_ID,
            currency: "EUR",
            country: COUNTRY,
            language: getApiPrefix(),
            promoCluster: PROMOTION_CLUSTER
        }
    };
    const privateDiscount = localStorage.getItem(PRIVATE_DISCOUNT);
    if (privateDiscount) {
        headers.headers["discountCode"] = privateDiscount;
    }
    if (isLoggedin()) {
        headers.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`
    }

    return headers
};

export const getDaysMiliseconds = (day) => {
    return day * 24 * 3600000;
}

export const getLocaleDateString = () => {
    const day = new Date();
    const dayName = days[day.getDay()];
    const currenTimestamp = day.getTime();
    const nextDayTimestamp = getDaysMiliseconds(dayName === 'days.6' ? 2 : 1) + currenTimestamp;
    const treeDaysTimestamp = getDaysMiliseconds(['days.4', 'days.5', 'days.6'].includes(dayName) ? 4 : 3) + currenTimestamp;

    const tomorrow = new Date(nextDayTimestamp);
    const threeDaysAfter = new Date(treeDaysTimestamp);
    return { tomorrow, threeDaysAfter }
}

const checkDaysOfMonths = () => {
    const dt = new Date();
    const month = dt.getMonth();
    const year = dt.getFullYear();
    return new Date(year, month, 0).getDate();
};

export const dateStringFormat = (action = "", date) => {
    if (!action && !date) {
        return "";
    } else if (action && !date) {
        const dateString = getLocaleDateString();
        date = dateString[action];
    };

    if (date) {
        const t = new Date(date.getTime());
        return `${t.getDate()} ${i18next.t(monthNames[t.getMonth()])}`;
    };

    if (!date) {
        const t = new Date();
        const currentMonth = t.getMonth();
        const currentDay = t.getDate() < checkDaysOfMonths() ? t.getDate() + 1 : 1;
        return `${currentDay} ${i18next.t(monthNames[currentMonth])}`;
    };
};

export const groupByKey = (arr = [], key) => {
    return arr.reduce((r, a) => {
        r[a[key]] = [...r[a[key]] || [], a];
        return r;
    }, {});
};

export const stripHTMLreg = /(<([^>]+)>)/ig;
export const regForBr = /(<([^br>]+)>)/ig;

export const countTimeBlog = (publishedTime) => {
    let date = new Date(publishedTime);
    const t = new Date(date.toLocaleDateString());
    return t.getDate() + ' ' + i18next.t(monthNames[t.getMonth()]) + ' ' + t.getFullYear();
};

export const Productoptions = (webInvertory = 10) => {
    const options = [];
    const count = webInvertory < PRODUCT_MAX_QUANTITY ? webInvertory : PRODUCT_MAX_QUANTITY
    for (let i = 1; i <= count; i++) {
        options.push({ key: i, value: i.toString(), text: i.toString() });
    }
    return options
}


export const addDays = (date, days) => {
    const copy = new Date(Number(date))
    copy.setDate(date.getDate() + days)
    return new Date(copy)
}


export const setStoreToBasket = (store = {}) => {
    const basket = localStorage.getItem(STORAGE_BASKET_INDEX) ? JSON.parse(localStorage.getItem(STORAGE_BASKET_INDEX)) : {};
    let newBasket = { ...basket, ...(store && store.StoreInfo && { store }) };

    localStorage.setItem(STORAGE_BASKET_INDEX, JSON.stringify(newBasket))

    return basket;
}

export const getbasketLineItems = () => {
    const basket = JSON.parse(localStorage.getItem(STORAGE_BASKET_INDEX));
    let basketLineItems = [];
    const basketKeys = basket && Object.keys(basket);
    if (basketKeys) {
        basketKeys.forEach(product => {
            if (basket[product].sizes && basket[product].sizes.length) {
                basket[product].sizes.forEach(elem => {
                    basketLineItems.push({ productId: elem.productId, quantity: elem.quantity })
                })
            }

        })
    }

    return basketLineItems
};

export const getDataLineItems = (data) => {
    let basketLineItems = [];
    const basketKeys = data && Object.keys(data);
    if (basketKeys) {
        basketKeys.forEach(product => {
            if (data[product].size && Object.keys(data[product].size).length) {
                basketLineItems.push({ productId: data[product].size.productId, quantity: Number(data[product].quantity) })
            }
        })
    }

    return basketLineItems
};

export const getWebInventory = (data) => {
    let webInventory = 0;
    if (data && data.length) {
        data.forEach(item => {
            webInventory += item.webInventory
        })
    }

    if (webInventory) {
        return false
    } else {
        return true
    }
}

export const getDateOfShopper = () => {
    let shopper = [];
    for (let i = 1; i < APPOINTMENT_MAX_DAYS + 1; i++) {
        shopper.push(addDays(new Date(), i))
    }
    return shopper;
}

export const getProductCategoryName = (item) => {
    const found = item.find(element => element.searchFilter === 'Collection');
    const categoryName = found ? found.values[0].value : '';
    return categoryName;
};

export const getBasketProductsBySizes = (basket = {}) => {
    let productList = [];
    const basketKeys = Object.keys(basket);
    if (basketKeys) {
        basketKeys.forEach(product => {
            if (basket[product].sizes && basket[product].sizes.length) {
                basket[product].sizes.forEach(elem => {
                    if (!elem.createdDate) {
                        elem.createdDate = new Date().getTime()
                    }
                    if (!elem.parentID) {
                        elem.parentID = product
                    }
                    productList.push({ ...basket[product], ...elem, parentId: +product })
                })
            }

        })
    }

    return productList
};

export const isExistProductInClickAndCollect = (barcodeList, productList) => {
    let isExisting = true;
    if (barcodeList && barcodeList.length) {
        barcodeList.forEach(product => {
            const selected = productList.findIndex(elem => elem.productId === product.productId);
            if (Number(product.stock) < Number(productList[selected].quantity)) {
                isExisting = false
            }
        })
    } else {
        isExisting = false
    }

    return isExisting
};

export const getExistingProductInBasket = (
    productId,
    productSizeId,
    data = null
) => {
    if (!productId || !productSizeId) {
        return {};
    }
    try {
        const baskets = data
            ? data
            : JSON.parse(localStorage.getItem(STORAGE_BASKET_INDEX));
        if (baskets[productId] && Array.isArray(baskets[productId].sizes)) {
            return (
                baskets[productId].sizes.find(
                    (item) => item.productId === productSizeId
                ) || {}
            );
        }
        return {};
    } catch (e) {
        return {};
    }
};

export const getShippingCity = (value) => {
    if (value) {
        localStorage.setItem(LOCAL_STORAGE_VARIABLES.shippingCity, value);
    }
    return localStorage.getItem(LOCAL_STORAGE_VARIABLES.shippingCity);
}

export const getBasketStore = () => {
    const basket = JSON.parse(localStorage.getItem(STORAGE_BASKET_INDEX));
    return {
        StoreInfo: basket && basket.store && (basket.store.StoreInfo || {}),
        storeDelivery: basket && basket.store && (basket.store.storeDelivery || '')
    }
}

export const getOperationSystem = () => {
    const osList = {
        iOS: ['iPhone', 'iPod', 'iPad', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'Pike'],
        Windows: ['OS/2', 'Pocket', 'Windows', 'Win16', 'Win32', 'WinCE'],
        Android: ['Android', 'BlackBerry'],
        Linux: ['Linux']
    };

    return Object.entries(osList).reduce((selectedOs, [os, value]) => {
        if (value.find((existValue) => navigator.platform.includes(existValue) || existValue.includes(navigator.platform))) {
            selectedOs = os;
        }
        return os;
    }, '')
}

export const createWebOrderLineItems = (items, basketInfo = null) => {
    const webOrderLineItems = [];
    const reduxState = store.getState();
    const discount = getDiscount(reduxState);
    const promotionDiscount = getPromotionDiscount(reduxState) || {};
    const allList = getAllClusterIds();
    const { clusterTotal } = getClusterTotals(items, allList);
    const packCategoriIds = getProductClusterPackBarcodes(reduxState);
    let key = 1;

    for (let i in items) {
        if (items[i].sizes && items[i].sizes.length) {

            if (items[i].packParent) {
                const { parentElementQuantity } = getPackDiscountPrice("basket", items[i], items[i].packParent)

                items[i].sizes && items[i].sizes.forEach((item) => {
                    webOrderLineItems.push({
                        productId: item.productId,
                        quantity: item.quantity,
                        currency: DEFAULT_CURRENCY,
                        itemType: item.itemType || PRODUCT_ITEM_TYPES.EAN,
                        pack: parentElementQuantity,
                        orderLineId: key,
                    });
                    key++
                })
            }
            // else if(allList.length && clusterTotal > 0) {
            //     items[i] && items[i].sizes.forEach((item) => {
            //         const itemPrice = item.quantity * (item.listPriceAfterDiscount || item.startingPrice);
            //         const promotionDisocunt = fixedInHundredth(item.specialDiscount) || 0;
            //         const isInBarcode = checkIsBarcodeInCluster(item.productReference)
            //         let totalItemDiscount = promotionDisocunt;

            //         if (basketInfo && isInBarcode) {
            //             const couponItemDiscount = getCouponSpecialDiscount(itemPrice, { ...basketInfo, price: clusterTotal });
            //             totalItemDiscount += couponItemDiscount;
            //         }

            //         webOrderLineItems.push({
            //             productId: item.productId,
            //             quantity: item.quantity,
            //             currency: DEFAULT_CURRENCY,
            //             itemType: item.itemType || PRODUCT_ITEM_TYPES.EAN,
            //             orderLineId: key,
            //             ...(totalItemDiscount > 0 && { specialDiscount: totalItemDiscount })
            //         });
            //         key++
            //     })
            // } 
            else {
                items[i] && items[i].sizes && items[i].sizes.forEach((item) => {
                    // const itemPrice = item.quantity * (item.listPriceAfterDiscount || item.startingPrice);
                    // const promotionDisocunt = fixedInHundredth(item.specialDiscount) || 0;
                    // let totalItemDiscount = promotionDisocunt;

                    // if (basketInfo) {
                    //     const couponItemDiscount = getCouponSpecialDiscount(itemPrice, basketInfo);
                    //     totalItemDiscount += couponItemDiscount;
                    // }
                    let totalItemDiscount = 0;
                    const isApproved = getIsPartOfProductCluster(item, discount, items)
                    if (isApproved && basketInfo) {
                        if (Object.keys(basketInfo).length) {
                            const couponItemDiscount = getCouponSpecialDiscount(item, basketInfo, discount, promotionDiscount, items, packCategoriIds);
                            totalItemDiscount += couponItemDiscount;
                        }
                    }

                    webOrderLineItems.push({
                        productId: item.productId,
                        quantity: item.quantity,
                        currency: DEFAULT_CURRENCY,
                        itemType: item.itemType || PRODUCT_ITEM_TYPES.EAN,
                        orderLineId: key,
                        ...(totalItemDiscount > 0 && { specialDiscount: totalItemDiscount })
                    });
                    key++
                })
            }

        }
    }

    return { webOrderLineItems, key };
}

export const isCookieAllowed = () => {

    if (localStorage.access && localStorage.access === "cookieRejected") {
        return false
    }
    return localStorage.access === 'cookieApproval'
}

export const getCurrentMenuItem = (menuList, checkBoutique = false) => {
    const pathList = window.location.pathname.split('/');
    const name = pathList[pathList.length - 1];

    let item;
    if (checkBoutique) {
        item = menuList.filter(
            (menuItem) => menuItem && menuItem.canonicalUrl && menuItem.canonicalUrl.includes(name) && menuItem.altUrl1 === 'boutique'
        );
    } else {
        item = menuList.filter(
            (menuItem) => menuItem && menuItem.canonicalUrl && menuItem.canonicalUrl.includes(name)
        );
    }

    return item || {};
}

export const compareBaskets = (oldBasket, newBasket) => {
    if (!oldBasket) return false;
    return Object.keys(oldBasket).reduce((acc, basketKey) => {
        const sizes = oldBasket[basketKey].sizes;
        if (sizes) {
            acc = sizes.reduce((sizeAcc, size, index) => {
                if (
                    size.quantity !== (
                        newBasket[basketKey]
                        && newBasket[basketKey].sizes
                        && newBasket[basketKey].sizes[index]
                        && newBasket[basketKey].sizes[index].quantity
                    )
                ) {
                    sizeAcc = true;
                }
                return sizeAcc;
            }, false) || acc;
        }
        return acc;
    }, false)
}

export const setIsAnuller = (status) => {
    localStorage.setItem('isAnulled', status)
    return status;
}


export const getBasketQuantity = (basket, promotion = false) => {
    /**Accepts basket which is actual basket from localStorage
     * promotion can be true or false
     * if promotion is false, quantity must be icreased with every single object quantity from product's sizes
     * if promotion is true, quantity must be increased with every single object quantity from
     * product's size which contains listPriceAfterDiscount field otherwise ignore
     *
     * promotion by default is false
     */

    return Object.values(basket).reduce((acc, product) => {
        if (product.sizes) {
            product.sizes.forEach((item) => {
                if (promotion && item.listPriceAfterDiscount !== undefined) {
                    acc += item.quantity;
                }

                if (!promotion) {
                    acc += item.quantity;
                }
            });
        }

        return acc;
    }, 0);
};

const anullerSpecialDiscount = (basket) => {
    Object.keys(basket).forEach((product) => {
        if (basket[product].sizes) {
            basket[product].sizes.forEach((item) => {
                if (item.priceAfterSpecialOperation || item.specialDiscount) {
                    delete item.priceAfterSpecialOperation;
                    delete item.specialDiscount;
                }
            })
        }
    })
}

export const getRealNumber = (num, size) => {
    const numbers = `${num}`.split('');
    const validNumber = numbers.reduce((acc, number, index) => {
        if (number === '.') {
            acc.position = index;
        } else {
            acc.number += number;
        }
        if (acc.position + size === acc.number.length) {
            acc.number += '.';
        }
        return acc;
    }, { number: '', start: 0 })
    return Number(validNumber.number);
}

export const fixedInHundredth = (number, roundChf = true) => {
    try {
        let num = number;
        let regexp = /^\d+\.\d{3,}$/;
        const regexExpression = regexp.test(Math.abs(num));
        if (num && regexExpression) {
            const negative = num < 0 ? -1 : 1;
            num = Math.round(getRealNumber(Math.abs(num), 2)) / 100;
            num = +(+num * negative).toFixed(2)
        }
        regexp = /^\d+\.\d{2}$/;
        if (num && regexp.test(Math.abs(num)) && DEFAULT_CURRENCY === "CHF" && !regexExpression && roundChf) {
            const lastDigit = Math.round(num * 10 % 1 * 10);
            num += ((lastDigit <= 7.5 ? lastDigit <= 5 / 2 ? 0 : 5 : 10) - lastDigit) / 100;
        }
        return num;
    } catch (error) {
        return 0;
    }
};

export const getDiscountList = (obj, quantity) => {
    const discounts = [];
    for (let i = quantity; i > 0; i--) {
        const discount = obj[`discount_${i}`];
        if (discount) {
            discounts.push(discount);
        }
    }

    return discounts;
}

export const capitalize = (string) => {
    const lower = string.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.slice(1)
}

export const getFunctionByString = (name) => {
    // eslint-disable-next-line
    return new Function(`${name}`)
}

const createRedonnerClose = (prefix) => {
    const closeIcon = document.createElement('div');
    closeIcon.innerText = 'X';
    closeIcon.classList.add('close-redonner-mobile');
    closeIcon.addEventListener('click', (event) => {
        getFunctionByString(`return ${prefix}closeWidget.bind(null)`)()();
        event.target.remove();
    })
    return closeIcon;
}

export const adyenLocalization = (language) => {
    switch(language){
        case "fr":
            return "fr-FR";
        case "de":
            return "de-DE";
        case "it":
            return "it-IT";
        case "en":
            return "en-EN";
        case "es":
            return "es-ES";
        default:
            return "it-IT";
    }
}

export const appendButton = () => {
    const prefix = window.uuids && window.uuids[window.uuids.length - 1];

    if (!prefix) {
        return false;
    }

    getFunctionByString(`${prefix}renderResellWidgetBtn()`)();
    const resellWidgetBtn = document.querySelector('#resell-widget-btn');

    if (resellWidgetBtn) {
        resellWidgetBtn.addEventListener('click', (e) => {
            if (window.screen.width <= 1) {
                if (document.querySelector(`#${prefix}resell-widget-xs`)) {
                    getFunctionByString(`return ${prefix}closeWidget.bind(null)`)()(e);
                } else {
                    getFunctionByString(`return ${prefix}renderResellWidgetMobile.bind(null)`)()(resellWidgetBtn);
                    const mobileContainer = document.querySelector(`#${prefix}resell-widget-xs`);
                    const redonnerClose = createRedonnerClose(prefix);
                    mobileContainer.nextElementSibling && mobileContainer.nextElementSibling.remove();
                    document.body.appendChild(redonnerClose);
                }
            } else {
                if (document.querySelector(`#${prefix}resell-widget-xs-up`)) {
                    getFunctionByString(`$return {prefix}closeWidget.bind(null)`)()(e);
                } else {
                    getFunctionByString(`return ${prefix}renderResellWidgetDesktop.bind(null)`)()(resellWidgetBtn);
                    const redonnerContainer = document.getElementById(`${prefix}resell-widget-bg`);

                    if (redonnerContainer) {
                        redonnerContainer.style.position = 'fixed';
                        redonnerContainer.style.height = '100vh';
                        redonnerContainer.style.width = '100%';
                    }
                }
            }
        })
    }
}

export const toggleRedonner = () => {
    const redonnerButton = document.querySelector('.layered-shadow');

    redonnerButton && redonnerButton.click();
}

export const closeRedonner = () => {
    const prefix = window.uuids && window.uuids[window.uuids.length - 1];

    if (!prefix) {
        return false;
    }

    const redonnerBg = document.getElementById(`${prefix}resell-widget-bg`);

    redonnerBg && redonnerBg.click()
}

export const getAxiosHeaders = () => {
    return {
        headers: {
            Authorization: localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "",
            'Content-Type': 'application/json',
        }
    };
}

export const getAllClusterIds = () => {
    const reduxState = store.getState();
    const clusterBarcodes = getProductClusterBarcodes(reduxState);
    const clusterPackBarcodes = getProductClusterPackBarcodes(reduxState);
    return Object.values(clusterPackBarcodes).reduce((acc, barcode) => {
        return [...acc, ...barcode]
    }, clusterBarcodes || []);
}

export const checkIsBarcodeInCluster = (productBarcode) => {
    const allList = getAllClusterIds();

    return allList.some((item) => item === productBarcode);
}

export const isProductsInBarcode = () => {
    const reduxState = store.getState();
    const basket = getBasket(reduxState);
    const allList = getAllClusterIds();
    const basketItemsKeys = Object.keys(basket);
    let isInBarcode = false;
    
    for (let i = 0; i < basketItemsKeys.length; i++) {
        const sizes = basket[basketItemsKeys[i]] && basket[basketItemsKeys[i]].sizes;

        if (!sizes) continue;

        for (let j = 0; j < sizes.length; j++) {
            const { productReference } = sizes[j];

            isInBarcode = allList.some((item) => item === productReference);

            if (isInBarcode) return isInBarcode;
        }
    }

    return isInBarcode;
}

export const getAreAllItemsDiscounted = (data) => {
    if (!data || !data.length) return false;
    return data.every(it => it.specialDiscount);
}

export const getIsApprovedDiscount = (basket, discount) => {
    const basketItemsKeys = basket && Object.keys(basket).length ? Object.keys(basket).filter(it => it !== 'store') : []
    if (!basketItemsKeys || !basketItemsKeys.length) return false;
    if (!discount || !discount.productCluster || !discount.productCluster.length) return true;
    let isInBarcode = false;
    for (let i = 0; i < basketItemsKeys.length; i++) {
        const sizes = basket[basketItemsKeys[i]] && basket[basketItemsKeys[i]].sizes;
        if (!sizes) continue;

        for (let j = 0; j < sizes.length; j++) {
            const { productReference } = sizes[j];

            isInBarcode = discount.productCluster.some((item) => item.barcode === productReference);
            if (isInBarcode) return isInBarcode;
        }
    }
    return isInBarcode
}

export const getIsHaveAtLeastOneOldPrice = () => {
    const reduxState = store.getState();
    const basket = getBasket(reduxState);
    const basketItemsKeys = basket && Object.keys(basket).length ? Object.keys(basket).filter(it => it !== 'store') : []
    if (!basketItemsKeys || !basketItemsKeys.length) return false;
    for (let i = 0; i < basketItemsKeys.length; i++) if (basket[basketItemsKeys[i]].oldPrice) return true
    return false;
}

export const checkNoExistUrl = (url) => {
    return url.endsWith('undefined')
}

export const isPhotoUrlEndsWithNull = (url) => {
    if (!url || (url && url.endsWith('null'))) return false
    return url
}


export const getIsPartOfProductCluster = (item, discount, basket = null) => {
    const reduxState = store.getState();
    const barcodes = getProductClusterBarcodes(reduxState)
    const packBarcodes = getProductClusterPackBarcodes(reduxState)
    if (!item || !item.productReference) return false;
    if (item.specialDiscount && packBarcodes && Object.keys(packBarcodes).length) return Object.keys(packBarcodes).some(pack => packBarcodes[pack].some(b => b === item.productReference))
    if (discount && (!discount.productCluster || !discount.productCluster.length)) return true;
    if (discount && discount.productCluster && discount.productCluster.length) return discount.productCluster.some((it) => it.barcode === item.productReference);
    if (barcodes && barcodes.length) return barcodes.some((it) => it === item.productReference)
    return false
}

export const getIsPartOfDiscountCluster = (item, discount) => {
    if (!item || !item.productReference) return false;
    if (discount && (!discount.productCluster || !discount.productCluster.length)) return true;
    if (discount && discount.productCluster && discount.productCluster.length) return discount.productCluster.some((it) => it.barcode === item.productReference);
    return false
}


export const getFullCouponSpecialDiscount = (items, basketData, discount, promotionDiscount = {}, packCategoryIds = {}) => {
    if (!basketData || !items) return 0;
    if (!discount) return basketData.price - basketData.discountedSum;
    const discountedPrice = Object.keys(items).reduce((acc, item) => {
      items[item].sizes && items[item].sizes.forEach((it) => {
        const isApproved = getIsPartOfProductCluster(it, discount)
  
        if (isApproved) {
          const itemDiscount = getCouponSpecialDiscount(it, basketData, discount, promotionDiscount, items, packCategoryIds)
          acc += itemDiscount || 0;
        }
      })
      return acc;
    }, 0) || 0
    const S = basketData.price - discountedPrice
    return parseFloat(S.toFixed(3)) || 0;
  }
